<template>
  <div>
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <b-row v-if="showFilters">
        <b-col>
          <Filters @filterValues="changeFilterValues" />
        </b-col>
      </b-row>
    </transition>

    <b-row>
      <b-col>
        <Datatable @show-filters-component="showFiltersComponent()" :filterValues="filterValues"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Datatable from '../components/Datatable.vue'
import Filters from '../components/Filters.vue'

export default {
  name: 'IndexUser',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    Datatable,
    Filters,
  },
  data() {
    return {
      showFilters: false,
      filterValues: []
    }
  },
  computed: {},
  methods: {
    showFiltersComponent() {
      this.showFilters = !this.showFilters
    },
    changeFilterValues(params) {
      this.filterValues = params
    }
  }
}
</script>
